<template>
    <div>
        <vx-card class="mt-8">
            <div class="card-title">
                <h2 class="mb-3">{{ LabelConstant.headingLabelTellusAboutYourCompetitors }}</h2>  
                <p>{{ LabelConstant.infoLabelMycompetitors }}</p>        
            </div>
            <div class="common-table"> 
                <div class="table-row-select">
                    <vs-button  title="Import Competitors" color="primary"  class="ml-auto" type="filled"  @click="importPopup = true">{{ LabelConstant.buttonLabelImportCompetitors }}</vs-button>
                </div>        
                <vs-table search :data="competitors" :max-items="5" pagination>
                    <template slot="thead">
                        <vs-th></vs-th>
                        <vs-th sort-key="Domain_Name">{{ LabelConstant.tableHeaderLabelDomainName }}</vs-th>
                        <vs-th sort-key="Company_Name">{{ LabelConstant.tableHeaderLabelCompanyName }}</vs-th>
                        <vs-th sort-key="Date">{{ LabelConstant.tableHeaderLabelDate }}</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td width="90">
                                <div class="table-action">
                                    <vs-button
                                        title="Edit"
                                        color="primary"
                                        type="filled"
                                        icon-pack="feather"
                                        icon="icon-edit"
                                        @click="editPopup = true, editCompetitorObj = data[indextr], companyName = data[indextr].Company_Name, domainName = data[indextr].Domain_Name"
                                        class="mr-2 float-left"
                                    ></vs-button>
                                    <vs-button
                                        title="Delete"
                                        color="danger"
                                        type="filled"
                                        icon-pack="feather"
                                        icon="icon-trash-2"
                                        @click="deletePopup = true, deleteCompetitorObj = data[indextr]"
                                        class="mr-0"
                                    ></vs-button>
                                </div>
                            </vs-td>
                            <vs-td :data="data[indextr].Domain_Name">{{ data[indextr].Domain_Name }}</vs-td>
                            <vs-td :data="data[indextr].Company_Name">{{ data[indextr].Company_Name }}</vs-td>
                            <vs-td :data="data[indextr].Date">{{ data[indextr].Date | tableDateFormat }}</vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </vx-card>
        <!-- Import Competitors modal -->
        <vs-popup class="popup-width" title="Import Competitors" :active.sync="importPopup">
            <template>
                <div class="vx-row find-people">
                    <div class="vx-col w-full">
                        <form data-vv-scope="importCompetitorsForm">
                            <div class="vx-row ">
                                <div class="vx-col w-full">
                                    <vx-card class="">
                                        <p class="mb-3">{{ LabelConstant.inputLabelInfo }}</p>
                                        <vs-textarea v-model="domainNames" name="domainNames" v-validate="'required'" class="form-textarea" row="5" placeholder="Enter domain names, one per line" />
                                        <span class="text-danger text-sm">{{errors.first("importCompetitorsForm.domainNames")}}</span>
                                    </vx-card>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </template>
            <div class="float-right mt-6 mb-8">
                <vs-button @click="importCompetitors()" color="primary" type="filled">{{ LabelConstant.buttonLabelImportCompetitors }}</vs-button>
                <vs-button color="grey" @click="cancelImportCompetitors()" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </vs-popup>
        <!-- Edit Competitor modal -->
        <vs-popup class="popup-width" title="Edit Competitor" :active.sync="editPopup">
            <template>
                <div class="vx-row find-people">
                    <div class="vx-col w-full">
                        <form data-vv-scope="editCompetitorsform">
                            <vx-card class="">
                                <div class="vx-row">
                                    <div class="vx-col w-full mb-4">
                                        <label>{{ LabelConstant.inputLabelCompanyName }}</label>
                                        <vs-input v-model="companyName" type="text" class="w-full"  />          
                                    </div>
                                    <div class="vx-col w-full">
                                        <label>{{ LabelConstant.inputLabelDomainName }}</label>
                                        <vs-input v-model="domainName" name="domainName" v-validate="'required'" type="text" class="w-full"  />
                                        <span class="text-danger text-sm">{{errors.first("editCompetitorsform.domainName")}}</span>
                                    </div>
                                </div>
                            </vx-card>
                        </form>
                    </div>
                </div>
            </template>
            <div class="float-right mt-6 mb-8">
                <vs-button @click="editCompetitor()" color="primary" type="filled">{{ LabelConstant.buttonLabelSaveCompetitors }}</vs-button>
                <vs-button color="grey" @click="cancelEditCompetitor()" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>  
        </vs-popup>
        <!-- Delete Cuatomer Popup -->
        <vs-popup class="delete-popup" :active.sync="deletePopup">
            <template>
                <div class="vx-row mb-3">
                    <div class="vx-col w-full" v-if="deleteCompetitorObj">
                        <div class="popup-icon">
                            <feather-icon icon="Trash2Icon"></feather-icon>
                        </div>
                        <h4>{{ LabelConstant.popupHeadingLabelDeleteCompetitor }}</h4>
                        <span class="deleted-no">{{ deleteCompetitorObj.Domain_Name }}</span>
                    </div>
                </div>
            </template>
            <div class="float-right mt-6 mb-8">
                <vs-button color="danger" type="filled" @click="deleteCompetitor()">{{ LabelConstant.buttonLabelDelete }}</vs-button>
                <vs-button @click="cancelDeleteCompetitor()" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import vSelect from 'vue-select'
import moment from "moment";
import { Validator } from "vee-validate";
const dict = {
  custom: {
    domainNames: {
      required: "Please enter domain names",
    },
    domainName: {
      required: "Please enter domain name",
    },
  },
};
Validator.localize("en", dict);

export default {
    components: {
        VxCard,    
        vSelect
    },
    data() {
        return {   
            importPopup: false,
            editPopup:false,
            competitors: [],
            domainNames: null,

            editCompetitorObj: null,
            companyName: null,
            domainName: null,

            deleteCompetitorObj: null,
            deletePopup: false
        };
    },
    created() {
        this.getCompetitors()
    },
    filters: {
        tableDateFormat: function (value) {
           return moment(value).format("MMMM DD, YYYY");
        },
    },
    methods: {
        getCompetitors(){
            this.$vs.loading();
            this.axios.get("/ws/FindAnyoneCompetitor/GetCompetitors").then((response) => {
                let data = response.data;
                this.competitors = data;
                this.competitors.map(function (manipulatedData) {
                    manipulatedData.sortableDate = new Date(manipulatedData.Date)
                    return manipulatedData;
                });
                this.$vs.loading.close();
            }).catch((e) => {
                this.$vs.loading.close();
                this.showError(e);
                this.$vs.notify({
                    title: "Error",
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    position: "top-right",
                    time: 4000,
                });
            });
        },
        cancelImportCompetitors(){
            this.domainNames = null
            this.importPopup = false
            setTimeout(() => {
                this.errors.clear();
            }, 100);
        },
        importCompetitors(){
            this.$validator.validateAll("importCompetitorsForm").then((result) => {
                if (result) {
                    let importCompetitorsParams = {
                        DomainNames: this.domainNames.split("\n")
                    }
                    this.$vs.loading();
                    this.axios.post("/ws/FindAnyoneCompetitor/ImportCompetitorList", importCompetitorsParams).then(() => {
                        this.cancelImportCompetitors()
                        this.getCompetitors()
                        this.$vs.notify({
                            title: "Success",
                            text: "Competitors saved successfully.",
                            color: "success",
                            iconPack: "feather",
                            icon: "icon-check",
                            position: "top-right",
                            time: 4000,
                        });
                        this.$vs.loading.close();
                    }).catch((e) => {
                        this.$vs.loading.close();
                        this.showError(e);
                        this.$vs.notify({
                            title: "Error",
                            text: e.response.data.Errors[0].Message,
                            color: "danger",
                            iconPack: "feather",
                            icon: "icon-alert-circle",
                            position: "top-right",
                            time: 4000,
                        });
                    });
                }
            });  
        },
        cancelEditCompetitor(){
            setTimeout(() => {
                this.errors.clear();
            }, 100);
            this.companyName = null
            this.domainName = null
            this.editPopup = false
            this.editCompetitorObj = null
        },
        editCompetitor(){
            this.$validator.validateAll("editCompetitorsform").then((result) => {
                if (result) {
                    let editCompetitorParams = {
                        Id: this.editCompetitorObj.Id,
                        Domain_Name: this.domainName,
                        Company_Name: this.companyName,
                        Date: this.editCompetitorObj.Date
                    }
                    this.$vs.loading();
                    this.axios.post("/ws/FindAnyoneCompetitor/EditCompetitor", editCompetitorParams).then(() => {
                        this.cancelEditCompetitor()
                        this.getCompetitors()
                        this.$vs.notify({
                            title: "Success",
                            text: "Competitor updated successfully.",
                            color: "success",
                            iconPack: "feather",
                            icon: "icon-check",
                            position: "top-right",
                            time: 4000,
                        });
                        this.$vs.loading.close();
                    }).catch((e) => {
                        this.$vs.loading.close();
                        this.showError(e);
                        this.$vs.notify({
                            title: "Error",
                            text: e.response.data.Errors[0].Message,
                            color: "danger",
                            iconPack: "feather",
                            icon: "icon-alert-circle",
                            position: "top-right",
                            time: 4000,
                        });
                    });
                }
            });
        },
        cancelDeleteCompetitor(){
            this.deletePopup = false
            this.deleteCompetitorObj = null
        },
        deleteCompetitor(){
            this.$vs.loading();
            this.axios.post("/ws/FindAnyoneCompetitor/DeleteCompetitor?Id="+this.deleteCompetitorObj.Id).then(() => {
                this.cancelDeleteCompetitor()
                this.getCompetitors()
                this.$vs.notify({
                    title: "Success",
                    text: "Competitor deleted successfully.",
                    color: "success",
                    iconPack: "feather",
                    icon: "icon-check",
                    position: "top-right",
                    time: 4000,
                });
                this.$vs.loading.close();
            }).catch((e) => {
                this.$vs.loading.close();
                this.showError(e);
                this.$vs.notify({
                    title: "Error",
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    position: "top-right",
                    time: 4000,
                });
            });
        }
    }
};
</script>
